<template>
  <div class="product-list main-panel large">
    <h3 class="panel-title">产品列表</h3>
    <div class="top">
      <el-form inline>
        <el-form-item label="产品名">
          <el-input v-model="form.pname" placeholder="产品名" size="mini" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" icon="el-icon-search" size="mini" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" height="500" v-loading="isLoading">
      <el-table-column align="center" label="产品名" prop="pname"></el-table-column>
      <el-table-column align="center" label="胚布名" prop="fabricName"></el-table-column>
      <el-table-column align="center" label="幅宽" prop="width"></el-table-column>
      <el-table-column align="center" label="克重" prop="weight"></el-table-column>
      <el-table-column align="center" label="成分" prop="ingredients"></el-table-column>
      <el-table-column align="center" label="单位" prop="companyname"></el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <div class="options">
            <i class="update-icon el-icon-edit" title="修改" @click="openLayer({id: scope.row.id})"></i>
            <el-popconfirm title="确认删除吗?" confirmButtonText='确认' cancelButtonText='取消' @confirm="del(scope.row.id)">
              <template #reference>
                <i class="del-icon el-icon-close" title="删除"></i>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>

      <template #append>
        <div class="load-more"
             :class="{'no-more': noMore}"
             v-if="list.length"
             @click="getList">{{ noMore ? "没有数据了~" : "点击加载下一页" }}</div>
      </template>
    </el-table>

    <my-layer v-if="Object.keys(layerInfo).length"
              :title="layerInfo.layerTitle"
              :component="layerComponent"
              @handle="layerInfo = {}; search();"
              :layer-info="layerInfo"
              @close="layerInfo = {}" />
  </div>
</template>

<script>
import {shallowRef} from "vue";
import MyLayer from "@/components/MyLayer/MyLayer";

export default {
  components: {MyLayer},
  data() {
    return {
      form: {
        pname: "",
        currentPage: 1,
        pageSize: 50
      },
      list: [],
      isLoading: false,
      noMore: false,

      layerComponent: undefined,
      layerInfo: {}
    }
  },

  mounted() {
    this.search()
  },

  methods: {
    async search() {
      this.form.currentPage = 1
      this.noMore = false
      this.list = []
      await this.getList()
    },

    async getList() {
      if (this.noMore) return false;
      this.isLoading = true
      const res = await this.$api.Base.Product.getList(this.form).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.form.currentPage += 1
        this.list.push(...res.data.data)

        if (this.list.length >= res.data.total) {
          this.noMore = true
        }
      } else {
        this.$message.error("获取列表失败")
      }
    },

    async openLayer(info = {}) {
      this.layerComponent = shallowRef((await import("@/views/base/product-edit/product-edit")).default)
      this.layerInfo = {...info, layerTitle: "产品修改"}
    },

    async del(id) {
      this.isLoading = true
      const res = await this.$api.Base.Product.del(id).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.$message.success("删除成功")
        this.form.pageSize = this.list.length - 1
        await this.search()
        this.form.pageSize = this.$options.data().form.pageSize
      } else {
        this.$message.error(res?.msg || "删除失败")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "product-list";
</style>
